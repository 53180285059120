//@author: travis
import { NgModule } from "@angular/core";
import { NgIf, NgForOf, NgClass, NgSwitch, NgSwitchCase, NgTemplateOutlet } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { S25TaxSchedulesComponent } from "./pricing-settings-components/s25-tax-schedules/s25.tax.schedules.component";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25PricingPrefsComponent } from "./pricing.prefs.component";
import { S25AccountingPrefsComponent } from "./pricing-settings-components/s25-accounting-prefs/s25.accounting.prefs.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25TaxSchedulesListComponent } from "./pricing-settings-components/s25-tax-schedules/s25.tax.schedules.list.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25PricingComponent } from "./s25.pricing.component";
import { S25PricingOrgSummaryComponent } from "./pricing-org-table-components/s25-pricing-org-summary/s25.pricing.org.summary.component";
import { S25PricingRateGroupComponent } from "./pricing-org-table-components/s25-pricing-rate-group/s25.pricing.rate.group.component";
import { S25PricingSetComponent } from "./pricing-org-table-components/s25-pricing-set/s25.pricing.set.component";
import { S25PricingOrgsComponent } from "./pricing-org-table-components/s25-pricing-orgs/s25.pricing.orgs.component";
import { S25PricingAdjustmentComponent } from "./pricing-org-table-components/s25-pricing-adjustment/s25.pricing.adjustment.component";
import { S25PricingOrganizationComponent } from "./pricing-org-table-components/s25-pricing-organization/s25.pricing.organization.component";
import { S25PricingTotalsComponent } from "./pricing-org-table-components/s25-pricing-totals/s25.pricing.totals.component";
import { S25PricingSubtotalChargesComponent } from "./pricing-org-table-components/s25-pricing-subtotal-charges/s25.pricing.subtotal.charges.component";
import { S25PricingTaxComponent } from "./pricing-org-table-components/s25-pricing-orgs/s25.pricing.tax.component";
import { S25PricingItemComponent } from "./pricing-org-table-components/s25-pricing-orgs/s25.pricing.item.component";
import { S25PricingOccurrencesComponent } from "./pricing-org-table-components/s25-pricing-orgs/s25.pricing.occurrences.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25PipesModule } from "../../pipes/s25.pipes.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25InvoiceComponent } from "./pricing-org-table-components/s25-invoice/s25.invoice.component";
import { S25ModalInvoiceFreshbooksComponent } from "./pricing-org-table-components/s25-invoice/s25-modal-invoice-freshbooks/s25.modal.invoice.freshbooks.component";
import { S25PricingTooltipComponent } from "./pricing-org-table-components/s25-pricing-orgs/s25.pricing.tooltip.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25PaymentsComponent } from "./s25-payments/s25.payments.component";
import { S25RateGroupsListComponent } from "./pricing-settings-components/s25-rate-groups/s25.rate.groups.list.component";
import { S25RateSchedulesComponent } from "./pricing-settings-components/s25-rate-schedules/s25.rate.schedules.component";
import { S25PricingSheetsComponent } from "./pricing-settings-components/s25-rate-schedules/s25.pricing.sheets.component";
import { S25FormulaBuilderComponent } from "./pricing-settings-components/s25-rate-schedules/s25-formula-builder/s25.formula.builder.component";
import { S25TimepickerModule } from "../s25-timepicker/s25.timepicker.module";
import { S25RateSchedulesListComponent } from "./pricing-settings-components/s25-rate-schedules/s25.rate.schedules.list.component";
import { S25OptModule } from "../s25-opt/s25.opt.module";
import { S25ModalPricingAdjustmentComponent } from "./pricing-org-table-components/s25-pricing-adjustment/s25.modal.pricing.adjustment.component";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25RadioModule } from "../s25-radio/s25.radio.module";
import { VoidPaymentComponent } from "./s25-payments/void.payment.component";
import { ModalModule } from "../modal/modal.module";
import { ModalEditTaxScheduleComponent } from "./modal.edit.tax.schedule.component";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25InfoMessageModule } from "../s25-info-message/s25.info.message.module";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25ButtonComponent } from "../../standalone/s25.button.component";
import { S25DateModule } from "../s25-dateformat/s25.date.module";
import { S25FadingSuccessMsgComponent } from "../s25-fading-success-msg/s25.fading.success.msg.component";
import { ModalConvertInvoiceComponent } from "./modal.convert.invoice.component";

@NgModule({
    declarations: [
        S25TaxSchedulesComponent,
        S25PricingPrefsComponent,
        S25AccountingPrefsComponent,
        S25TaxSchedulesListComponent,
        S25PricingComponent,
        S25PricingOrgSummaryComponent,
        S25PricingRateGroupComponent,
        S25PricingSetComponent,
        S25PricingOrgsComponent,
        S25PricingAdjustmentComponent,
        S25PricingOrganizationComponent,
        S25PricingTotalsComponent,
        S25InvoiceComponent,
        S25ModalInvoiceFreshbooksComponent,
        S25PricingSubtotalChargesComponent,
        S25PricingTaxComponent,
        S25PricingItemComponent,
        S25PricingOccurrencesComponent,
        S25PricingTooltipComponent,
        S25PaymentsComponent,
        S25RateGroupsListComponent,
        S25RateSchedulesComponent,
        S25PricingSheetsComponent,
        S25FormulaBuilderComponent,
        S25RateSchedulesListComponent,
        S25ModalPricingAdjustmentComponent,
        VoidPaymentComponent,
        ModalEditTaxScheduleComponent,
        ModalConvertInvoiceComponent,
    ],
    imports: [
        NgIf,
        NgForOf,
        NgClass,
        NgSwitch,
        NgSwitchCase,
        FormsModule,
        NgbModule,
        NgTemplateOutlet,
        S25SimpleCollapseModule,
        S25DropdownPaginatedModule,
        S25DatepickerModule,
        S25EditableModule,
        S25MultiselectModule,
        S25TableModule,
        S25LoadingInlineModule,
        S25PipesModule,
        S25CheckboxModule,
        S25ModalModule,
        S25TimepickerModule,
        S25OptModule,
        S25RichTextEditorModule,
        S25RadioModule,
        ModalModule,
        S25IconModule,
        S25ToggleButtonModule,
        S25InfoMessageModule,
        PopoverModule,
        S25ButtonComponent,
        S25DateModule,
        S25FadingSuccessMsgComponent,
    ],
    providers: [
        S25TaxSchedulesComponent,
        S25PricingPrefsComponent,
        S25AccountingPrefsComponent,
        S25TaxSchedulesListComponent,
        S25PricingComponent,
        S25PricingOrgSummaryComponent,
        S25PricingRateGroupComponent,
        S25PricingSetComponent,
        S25PricingOrgsComponent,
        S25PricingAdjustmentComponent,
        S25PricingOrganizationComponent,
        S25PricingTotalsComponent,
        S25InvoiceComponent,
        S25ModalInvoiceFreshbooksComponent,
        S25PricingSubtotalChargesComponent,
        S25PricingTaxComponent,
        S25PricingItemComponent,
        S25PricingOccurrencesComponent,
        S25PricingTooltipComponent,
        S25PaymentsComponent,
        S25RateGroupsListComponent,
        S25RateSchedulesComponent,
        S25PricingSheetsComponent,
        S25FormulaBuilderComponent,
        S25RateSchedulesListComponent,
        S25ModalPricingAdjustmentComponent,
        VoidPaymentComponent,
        ModalEditTaxScheduleComponent,
        ModalConvertInvoiceComponent,
    ],
    exports: [
        S25TaxSchedulesComponent,
        S25PricingPrefsComponent,
        S25AccountingPrefsComponent,
        S25TaxSchedulesListComponent,
        S25PricingComponent,
        S25PricingOrgSummaryComponent,
        S25PricingRateGroupComponent,
        S25PricingSetComponent,
        S25PricingOrgsComponent,
        S25PricingAdjustmentComponent,
        S25PricingOrganizationComponent,
        S25PricingTotalsComponent,
        S25InvoiceComponent,
        S25ModalInvoiceFreshbooksComponent,
        S25PricingSubtotalChargesComponent,
        S25PricingTaxComponent,
        S25PricingItemComponent,
        S25PricingOccurrencesComponent,
        S25PricingTooltipComponent,
        S25PaymentsComponent,
        S25RateGroupsListComponent,
        S25RateSchedulesComponent,
        S25PricingSheetsComponent,
        S25FormulaBuilderComponent,
        S25RateSchedulesListComponent,
        S25ModalPricingAdjustmentComponent,
        VoidPaymentComponent,
        ModalEditTaxScheduleComponent,
        ModalConvertInvoiceComponent,
    ],
})
export class S25PricingModule {
    constructor() {}
}
